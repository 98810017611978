<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
import { getAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'
import moment from 'moment'
import { unitDataList } from '@/utils/textFile'
export default {
  name: 'eatDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        detailsList: [],
        salePrice: null,
        vipPrice: null,
        availableRules: ['0', '1'],
        canBuy: '1'
      },
      timeArea: [],
      storeData: [],
      unitData: unitDataList,
      isShowSnapshot: false
    }
  },
  watch: {
    'detail.mainBodyId'(a, b) {
      if (a) {
        this.getBaseDataInit(a)
        this.getMainBody(a)
      }
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/goods/farmAgritainment/detail?id=${id}`
        })
        .then(result => {
          this.detail.availableRules = result.availableRules ? result.availableRules.split(',') : []
        })

    this.storeSearch()
    this.$forceUpdate()
  },
  methods: {
    getMainBody(id) {
      getAction(`/api/baseMonitor/queryBaseMonitorList?shopId=${id}`).then(e => {
        this.isShowSnapshot = e.data.length > 0 ? true : false
      })
    },
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`
      })
      this.storeData = searchResult.map(el => {
        return {
          name: el.mainBodyName,
          value: el.id
        }
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称(76字符内)',
            type: 'input',
            cols: 12,
            key: 'name',
            props: {
              maxLength: 76
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '关联店铺',
            type: 'dropDownInput',
            cols: 12,
            key: 'mainBodyName',
            onInputSearch: this.storeSearch,
            valueKey: 'mainBodyId',
            inputProps: {
              placeholder: '搜索店铺',
              addonBefore: <a-icon type="search" />
            },
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '预约',
            type: 'select',
            cols: 6,
            gutter: 10,
            key: 'subscribe',
            defaultValue: '0',
            typeData: [
              {
                name: '免预约',
                value: '0'
              },
              {
                name: '电话预约',
                value: '1'
              }
            ],
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '退款',
            type: 'select',
            cols: 6,
            gutter: 10,
            key: 'refundExplain',
            defaultValue: '0',
            typeData: [
              {
                name: '随时退',
                value: '0'
              },
              {
                name: '不可退',
                value: '1'
              }
            ],
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '剩余库存',
            type: 'inputNumber',
            cols: 12,
            key: 'stockNum',
            rules: [
              {
                required: true,
                type: 'number'
              }
            ],
            props: {
              min: 0
            }
          },
          {
            type: 'row',
            cols: 6,
            children: [
              {
                name: '有效期',
                type: 'rangePicker',
                cols: 24,
                keys: ['startDate', 'endDate'],
                rules: [
                  {
                    required: true,
                    message: '请选择有效期'
                  }
                ]
              }
            ]
          },
          {
            name: '可用规则',
            type: 'checkBoxButton',
            key: 'availableRules',
            cols: 6,
            typeData: [
              {
                name: '法定节假日可用',
                value: '0'
              },
              {
                name: '周末可用',
                value: '1'
              }
            ]
            // rules: [
            //   {
            //     required: true,
            //     type: 'array',
            //     message: '请选择可用规则'
            //   }
            // ]
          },
          {
            name: 'Banner选择',
            type: 'radioButton',
            key: 'bannerType',
            cols: 12,
            defaultValue: '0',
            typeData: [
              {
                name: 'banner图片',
                value: '0',
                display: true
              },
              {
                name: '视频地址',
                value: '2',
                display: true
              },
              {
                name: '数字基地监控视频',
                value: '1',
                display: this.isShowSnapshot
              }
            ].filter(e => e.display),
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '开放购买（默认选择是）',
            type: 'radioButton',
            key: 'canBuy',
            cols: 12,
            typeData: [
              {
                name: '是',
                value: '1'
              },
              {
                name: '否',
                value: '0'
              }
            ],
            rules: [
              {
                required: true
              }
            ]
          },
          {
            name: '周边推荐（默认选择否）',
            type: 'radioButton',
            key: 'recommend',
            defaultValue: '0',
            cols: 12,
            typeData: [
              {
                name: '是',
                value: '1'
              },
              {
                name: '否',
                value: '0'
              }
            ],
            rules: [
              {
                required: true
              }
            ]
          }
        ]
      }
    },
    getFormBox() {
      return {
        type: 'cardFormBox',
        children: [this.getForm2(), this.getForm23()]
      }
    },
    getForm2() {
      return {
        type: 'cardForm',
        title: () => (
          <div>
            <span style={{ color: '#f5222d' }}>*</span>Banner（尺寸750*420PX）限制1张
          </div>
        ),
        data: [
          {
            type: 'iconUpload',
            cols: 12,
            typeData: [
              {
                maxLength: 1,
                key: 'img',
                desc: '<br /> 支持格式.png/.jpg'
              }
            ],
            rules: [
              {
                required: true,
                message: '请上传图片',
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getForm23() {
      return {
        type: 'cardForm',
        title: '宣传视频',
        data: [
          {
            type: 'iconUploadVideo',
            cols: 12,
            typeData: [
              {
                maxLength: 1,
                key: 'videoUrl',
                desc: '视频上传尺寸<20M <br /> 支持格式.mov/.mp4/.wmv/.avi'
              }
            ]
          }
        ]
      }
    },
    getForm2Copy() {
      return {
        type: 'cardForm',
        title: '数字基地监控视频',
        data: [this.isShowSnapshot ? this.getBaseDataInit(this.detail.mainBodyId) : {}]
      }
    },
    getBaseDataInit(shopId = null) {
      return {
        type: 'baseUpload',
        key: 'monitorCode',
        maxLength: 1,
        cols: 24,
        shopId: shopId,
        setValue: this.detail.monitorCode,
        setFirm: false
      }
    },
    getForm3() {
      let that = this
      return {
        type: 'table',
        showFootButton: false,
        showRowSelect: false,
        showPagination: false,
        columns: [
          {
            dataIndex: 'salePrice',
            align: 'left',
            title: '现价',
            width: '15%',
            customRender: function(text, records) {
              const onInput = data => {
                if (data.target.value) {
                  if (!/[0-9\.]/.test(data.target.value)) {
                    data.target.value = null
                    that.$message.warning('请输入数字！')
                    return false
                  } else {
                    that.detail.salePrice = data.target.value
                  }
                } else {
                  that.detail.salePrice = ''
                }
              }
              return (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  placeholder="价格"
                  addon-before="￥"
                  onInput={onInput}
                  value={text}
                />
              )
            }
          },
          {
            dataIndex: 'vipPrice',
            align: 'left',
            title: '会员最低价',
            width: '15%',
            customRender: function(text, records) {
              const onInput = data => {
                if (data.target.value) {
                  if (Number(data.target.value) > Number(that.detail.salePrice)) {
                    data.target.value = null
                    that.$message.warning('会员价应低于现价！')
                    return false
                  } else if (!/[0-9\.]/.test(data.target.value)) {
                    data.target.value = null
                    that.$message.warning('请输入数字！')
                    return false
                  } else {
                    that.detail.vipPrice = data.target.value
                  }
                } else {
                  that.detail.vipPrice = ''
                }
              }
              return (
                <a-input
                  disabled={that.detail.canBuy == '0'}
                  type="number"
                  placeholder="会员价（不填没有会员价）"
                  addon-before="￥"
                  onInput={onInput}
                  value={text}
                />
              )
            }
          },
          {
            dataIndex: 'v1',
            align: 'center',
            title: 'V1',
            width: 50
          },
          {
            dataIndex: 'v2',
            align: 'center',
            title: 'V2',
            width: 50
          },
          {
            dataIndex: 'v3',
            align: 'center',
            title: 'V3',
            width: 50
          },
          {
            dataIndex: 'v4',
            align: 'center',
            title: 'V4',
            width: 50
          },
          {
            dataIndex: 'v5',
            align: 'center',
            title: 'V5',
            width: 50
          },
          {
            dataIndex: 'v6',
            align: 'center',
            title: 'V6',
            width: 50
          }
        ],
        dataSource: [
          {
            salePrice: this.detail.salePrice,
            vipPrice: this.detail.vipPrice,
            ...apiTool.getVipPrice(this.detail)
          }
        ]
      }
    },
    getForm4() {
      let that = this
      return {
        type: 'table',
        showFootButton: true,
        showRowSelect: false,
        showPagination: false,
        // table新增按钮回调
        onAddData: () => {
          return {
            recommendState: '1',
            name: null,
            price: null,
            quantity: null,
            unit: null
          }
        },
        columns: [
          {
            dataIndex: 'recommendState',
            align: 'center',
            title: '推荐',
            width: 50,
            customRender: function(text, records, index) {
              const onChange = e => {
                records.recommendState = e.target.checked ? '0' : '1'
                // that.$forceUpdate()
              }
              return <a-checkbox checked={text == '0' ? true : false} onChange={onChange}></a-checkbox>
            }
          },
          {
            dataIndex: 'name',
            align: 'left',
            title: '套餐菜品',
            width: 150,
            customRender: function(text, records, index) {
              const onInput = data => {
                records.name = data.target.value
              }
              return records.isEdit ? (
                <a-input placeholder="菜品名称" onChange={onInput} value={text} />
              ) : (
                <div>{text}</div>
              )
            }
          },
          {
            dataIndex: 'price',
            align: 'left',
            title: '原价',
            width: 150,
            customRender: function(text, records) {
              const onInput = data => {
                records.price = data.target.value
              }
              return records.isEdit ? (
                <a-input
                  type="number"
                  addon-before="￥"
                  placeholder="产品原价（比价参考）"
                  onChange={onInput}
                  value={text}
                />
              ) : (
                <div>{text}</div>
              )
            }
          },
          {
            dataIndex: 'quantity',
            align: 'left',
            title: '分量',
            width: 100,
            customRender: function(text, records, index) {
              const onInput = data => {
                records.quantity = data.target.value
              }
              return records.isEdit ? (
                <a-input type="number" placeholder="菜品数量" onChange={onInput} value={text} />
              ) : (
                <div>{text}</div>
              )
            }
          },
          {
            dataIndex: 'unit',
            align: 'left',
            title: '份量单位',
            width: 100,
            customRender: function(text, records, index) {
              const onChange = data => {
                records.unit = data
              }
              return records.isEdit ? (
                <a-select onChange={onChange} style={{ width: '200px' }} placeholder="分量单位" defaultValue={text}>
                  {that.unitData.map(e => {
                    return <a-select-option value={e.value}>{e.name}</a-select-option>
                  })}
                </a-select>
              ) : (
                <div>{text}</div>
              )
            }
          },
          {
            dataIndex: 'a10',
            align: 'center',
            width: 100,
            title: '操作',
            tableType: 'editDelete',
            onSave: (text, records) => {
              if (records.name && records.price) {
                records.isEdit = false
              } else {
                this.$message.error('请填入套餐菜品/原价')
              }
            }
          }
        ],
        dataSource: this.detail.detailsList
      }
    },
    getForm5() {
      return {
        type: 'cardForm',
        cols: 24,
        showCard: false,
        data: [
          {
            type: 'rich',
            key: 'richText',
            cols: 24
          }
        ]
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            if (data.detailsList.length == 0) {
              this.$message.warning('请输入推荐套餐！')
              return
            } else {
              try {
                data.detailsList.forEach(element => {
                  if (element.isEdit) {
                    this.$message.warning('请先保存推荐套餐！')
                    throw new Error('结束！')
                  }
                })
              } catch (error) {
                console.log(error)
                return
              }
            }

            let monitorCode = null
            let monitorImg = null
            if (data.monitorCode instanceof Array && data.bannerType == 1 && data.monitorCode.length > 0) {
              monitorCode = (
                data.monitorCode.find(e => e.select) || {
                  monitorCode: ''
                }
              ).monitorCode
              monitorImg = (
                data.monitorCode.find(e => e.select) || {
                  monitorImg: ''
                }
              ).monitorImg
            }

            if (!data.startDate || !data.endDate) {
              this.$message.warning('请选择有效期！')
              return
            }

            if (data.availableRules.length > 0) {
              let arr = []
              data.availableRules.forEach(e => {
                arr.push(['法定节假日可用', '周末可用'][e])
              })
              data.availableRulesName = arr.toString()
            } else {
              data.availableRulesName = ''
            }

            let upDown = this.detail.id ? this.detail.upDown : '0'

            api.command[this.detail.id ? 'edit' : 'create']
              .call(this, {
                url: '/goods/farmAgritainment',
                params: {
                  ...data,
                  startDate: moment(data.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
                  endDate: moment(data.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss'),
                  availableRules: data.availableRules.toString(),
                  upDown: upDown,
                  monitorCode: monitorCode,
                  monitorImg: monitorImg
                },
                isPost: false
              })
              .then(result => {
                this.$router.push('/commodityManagement/eat')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/commodityManagement/eat')
          }
        }
      ]
      const right = [
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            getAction(
              `/api/goods/farmAgritainment/${this.detail.upDown == 0 ? 'down' : 'up'}?id=${this.detail.id}`
            ).then(e => {
              if (e.code == 200) {
                this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                setTimeout(() => {
                  this.$router.push('/commodityManagement/eat')
                }, 500)
              } else {
                this.$message.error(e.msg)
              }
            })
          }
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/goods/farmAgritainment?id=${this.detail.id}`,
              isPost: false
            })
            setTimeout(() => {
              this.$router.push('/commodityManagement/eat')
            }, 500)
          }
        }
      ]
      return {
        left,
        right: this.detail.id && right
      }
    }
  },
  render() {
    return (
      <DetailFormGroup
        foot={this.getFoot()}
        form={this.detail}
        typeData={{
          mainBodyName: this.storeData
        }}
        data={[
          this.getForm1(),
          this.getFormBox(),
          this.isShowSnapshot && this.getForm2Copy(),
          this.getForm3(),
          this.getForm4(),
          this.getForm5()
        ]}
      />
    )
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
